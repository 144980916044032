<template>
  <div class="associate-package mt-16" style="white-space: pre-line">
    <!-- <div class="flex-center relative" style="padding-bottom: 20px">
      <div class="mr-auto title">
        {{ packagePayload.categoryName }}
        <div
          v-if="packagePayload.level"
          style="
            color: white;
            width: fit-content;
            padding: 3px;
            display: inline-block;
            font-size: 1.6rem;
            border-radius: 1rem;
            margin: -10px 4px;
          "
          :style="
            packagePayload.maxSeverity == 2
              ? packagePayload.severity == 1
                ? 'background-color: #bad6a4;'
                : 'background-color: #f34e4e;'
              : packagePayload.severity == 1
              ? 'background-color: #bad6a4;'
              : packagePayload.severity == 2
              ? 'background-color: #e7c74e;'
              : 'background-color: #f34e4e;'
          "
        >
          {{ packagePayload.level }}
        </div>
      </div>

      <div
        class="icon-arrow relative flex-mid"
        @click="statusShow = !statusShow"
      >
        <div :class="`arrow-show ${packagePayload.statusShow ? 'show' : ''}`">
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.638096 5.21537C0.796633 5.37297 1.01109 5.46143 1.23463 5.46143C1.45818 5.46143 1.67264 5.37297 1.83117 5.21537L4.86887 2.21999L7.86425 5.21537C8.02279 5.37297 8.23725 5.46143 8.46079 5.46143C8.68433 5.46143 8.89879 5.37297 9.05733 5.21537C9.13664 5.13671 9.19958 5.04312 9.24254 4.94001C9.2855 4.8369 9.30762 4.7263 9.30762 4.6146C9.30762 4.5029 9.2855 4.3923 9.24254 4.28919C9.19958 4.18608 9.13664 4.09249 9.05733 4.01383L5.46963 0.426139C5.39097 0.346831 5.29739 0.283882 5.19428 0.240924C5.09117 0.197966 4.98057 0.175849 4.86887 0.175849C4.75716 0.175849 4.64657 0.197966 4.54345 0.240924C4.44034 0.283882 4.34676 0.346831 4.2681 0.426139L0.638096 4.01383C0.558787 4.09249 0.495838 4.18608 0.45288 4.28919C0.409922 4.3923 0.387804 4.5029 0.387804 4.6146C0.387804 4.7263 0.409922 4.8369 0.45288 4.94001C0.495838 5.04312 0.558787 5.13671 0.638096 5.21537Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
    </div> -->

    <div :class="`expend-package ${statusShow ? 'showing' : ''}`">
      <div class="sub-package">
        <div v-if="statusShow" class="line-package mt-32"></div>
        <div class="flex">
          <div class="checkbox mt-26">
            <div
              @click="toggleSelectHandler"
              class="circle flex-mid"
              :style="
                associatePackage.selected == 2 ? 'background:#BED5B0' : ''
              "
            >
              <div v-if="associatePackage.select" class="in-circle"></div>
            </div>
          </div>
          <div class="body mt-4">
            <div class="include-label mt-15">
              {{ associatePackage.packageInformation.packageName }}
            </div>

            <div
              class="include-label"
              v-if="
                associatePackage.itemInformation.length > 0 && show == false
              "
              @click="show = true"
              style="cursor: pointer; text-decoration: underline"
            >
              {{ $t("seeDetail") }}
            </div>
            <div
              class="include-label"
              v-if="associatePackage.itemInformation.length > 0 && show"
              @click="show = false"
              style="cursor: pointer; text-decoration: underline"
            >
              {{ $t("hideDetail") }}
            </div>
            <div v-if="show">
              <div
                v-for="(description, i) in associatePackage.itemInformation"
                :key="i"
                class="include-label mt-2"
              >
                • {{ description.name }}
              </div>
            </div>
            <div class="packageDescription-label" style="white-space: pre-line">
              {{ associatePackage.packageInformation.packageDescription }}
            </div>
          </div>
          <div class="pricing Avenir fs-14 mt-26">
            <div
              v-if="
                associatePackage.packageInformation.fullPrice >
                associatePackage.packageInformation.netPrice
              "
              class="pricing-sale-mobile"
            >
              <div class="old-price">
                {{
                  numberWithCommas(
                    associatePackage.packageInformation.fullPrice
                  )
                }}
              </div>
              <div class="sale">
                {{
                  numberWithCommas(associatePackage.packageInformation.netPrice)
                }}
              </div>
            </div>
            <div v-else>
              {{
                numberWithCommas(associatePackage.packageInformation.fullPrice)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AssocitaePackageCard",
  props: {
    hospitalId: {
      type: String,
      required: true,
    },
    associatePackage: {
      required: true,
    },
    statusShowValue: {
      required: false,
      default: false,
    },
    toggleSelect: {
      type: Function,
      required: true,
    },
    mainHospitalPackageId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      statusShow: this.statusShowValue,
      show: this.associatePackage.show,
    };
  },
  methods: {
    numberWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".-";
    },
    associatedPackages(associatePackage) {
      return this.$store.getters.getAssociatedPackages(
        associatePackage.hospitalId
      );
    },
    toggleSelectHandler() {
      this.$store.commit("selectAssociatePackage", {
        mainHospitalPackageId: this.mainHospitalPackageId,
        associatePackage: this.associatePackage,
      });

      this.toggleSelect(this.associatePackage, true);
    },
  },
});
</script>
<style lang="scss" scoped>
.associate-package {
  background-color: #fff;
  padding: 0rem 2rem;
  border-radius: 3rem;
  // font-family: "DBAdmanX";
  box-shadow: -5px -5px 5px rgba(232, 232, 232, 0.25),
    5px 5px 10px rgba(163, 162, 162, 0.25);

  .title {
    color: #206b5e;
    font-size: 1.8rem;
    font-weight: 400;
  }

  .price {
    color: #000;
    font-size: 2rem;
  }

  .price-changed {
    .old-price {
      color: #b9b9b9;
      font-weight: 500;
      font-size: 2rem;
      text-decoration: line-through;
    }

    .price {
      color: #c31212;
      text-decoration: none;
      font-size: 2rem;
    }
  }

  .icon-arrow {
    margin-left: 7rem;
    cursor: pointer;
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 3.3rem;
    background-color: #f3f3e7;
    filter: drop-shadow(3px 4px 4px rgba(148, 148, 148, 0.25));

    .arrow-show {
      position: absolute;
      transform: rotate(180deg);
      transition: 0.3s;
    }

    .arrow-show.show {
      position: absolute;
      transform: rotate(0deg);
    }
  }

  .line-package {
    width: 100%;
    height: 0.1rem;
    margin: 0px;
    background: #cfcfcf;
  }

  .sub-package {
    padding-bottom: 20px;

    .checkbox {
      margin-right: 1rem;
      // width: 5rem;
    }

    .body {
      width: calc(100% - 10rem);
    }

    .pricing {
      width: 5rem;
      text-align: right;

      .pricing-sale-mobile {
        text-align: right;
        display: block;
      }

      .old-price {
        color: #b9b9b9;
        font-size: 1.2rem;
        text-decoration: line-through;
      }

      .sale {
        color: #c31212;
      }
    }

    @media only screen and (min-width: 600px) {
      .body {
        width: calc(100% - 16rem);
      }

      .pricing {
        width: 11rem;

        .pricing-sale-mobile {
          display: flex;
        }

        .old-price {
          margin-left: 1rem;
        }

        .sale {
          color: #c31212;
          margin-left: auto;
        }
      }
    }

    .include-label {
      color: #999999;
      font-size: 1.8rem;
    }

    .prerequisite-notice {
      color: #e78181;
      font-size: 1.5rem;
    }

    .packageDescription-label {
      color: #f5af6d;
      font-size: 1.5rem;
    }
  }
}

.circle {
  border-radius: 2.9rem;
  width: 2.9rem;
  height: 2.9rem;
  background-color: #e3ebde;
  cursor: pointer;

  .in-circle {
    border-radius: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #206b5e;
  }
}
</style>
